<template>
  <div id="app">
    <div v-if="isLoggedIn">
      <!-- <Debug v-if="debugMode"></Debug>
      <SessionTimer /> -->
      <div class="container">
        <nav class="navbar navbar-expand">
          <ul class="navbar-nav mr-auto px-0">
            <li class="nav-item">
              <router-link :style="clientColor" v-if="currentRouteName !== 'targets'" to="/"
                class="nav-link p-button">zurück</router-link>
            </li>
          </ul>
        </nav>
        <router-view />
      </div>
    </div>
    <div v-else class="cenflow-logo">
      <span class="helper"></span>
    </div>
  </div>
</template>

<script>
import SweetAlertService from "@/services/sweetalert";
// import Debug from "@/components/Debug";
// import SessionTimer from "@/components/SessionTimer";

import { defineRule } from 'vee-validate';
import { useLoading } from 'vue-loading-overlay'
import { isEmpty } from 'lodash';

const $loading = useLoading({
  // options
});

defineRule('required', value => {
  if (!value || !value.length) {
    return 'Dies ist ein Pflichtfeld';
  }
  return true;
});

export default {
  name: 'App',
  // components: { Debug, SessionTimer },
  data() {
    return {
      debugMode: true,
      message: null,
      // loader: $loading,
      client: {
        id: null,
        name: null,
        url: null
      }
    }
  },
  computed: {
    isLoggedIn() {
      return this.$store.state.auth.loggedIn === true;
    },
    currentRouteName() {
      return this.$route.name;
    },
    clientColor() {
      const { background_color, text_color } = this.$store.state.auth.jobFlowClient ?? {};

      if (isEmpty(background_color)) {
        return {
          'backgroundColor': '#ff0000',
          'color': '#fff'
        };
      } else {
        return {
          'backgroundColor': background_color,
          'color': text_color
        };
      }
    }
  },
  created() {

    if (window.self === window.top) {
      return; // if w.self === w.top, we are not in an iframe
    }
    // send message to parent about height updates
    this.sendHeightToParent(); //whenever the page is loaded

  },
  mounted() {
    this.jwtLogin();
    window.addEventListener("resize", this.sendHeightToParent); // whenever the page is resized
    window.addEventListener("readystatechange", this.sendHeightToParent); // whenever the page is resized
    var observer = new MutationObserver(this.sendHeightToParent); // whenever DOM changes PT1
    var config = { attributes: true, childList: true, characterData: true, subtree: true }; // PT2
    observer.observe(window.document, config); // PT3
  },
  methods: {
    sendHeightToParent() {
      let height = document.body.offsetHeight;

      const windowHeight = window.innerHeight;
      const minHeight = windowHeight * 0.7;

      if (height < minHeight) {
        height = minHeight;
      }
      console.log("Sending height as " + height + "px");
      parent.postMessage({ "height": height }, "*");
    },


    resizeItNowPrivate() {
      let iframe = document.getElementById('healthy_behavior_iframe');
      // if (!iframe) return;
      console.log("there's an iFrame")
      var body = iframe.contentWindow.document.body,
        html = iframe.contentWindow.document.documentElement;
      var height = Math.max(body.scrollHeight ?? 0, body.offsetHeight ?? 0,
        html.clientHeight ?? 0, html.scrollHeight ?? 0, html.offsetHeight ?? 0);
      console.log('height: ', height)
      const windowHeight = window.innerHeight;
      const minHeight = windowHeight * 0.7;

      if (height < minHeight) {
        height = minHeight;
      }

      iframe.style.height = height + 'px';
    },

    async jwtLogin() {
      /* const loader = $loading.show({
         container: null,
         canCancel: false,
         loader: 'dots',
         opacity: 0.4
       });*/

      this.$store.dispatch("auth/login").then(
        () => {
          setTimeout(() => {
            // loader.hide()
          }, 2000)
        },
        (err) => {
          // loader.hide()
          this.$swal(SweetAlertService.error(err))
        }
      );
    }
  }
}
</script>

<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  overflow: auto;
}

body {
  background: #f6f6f6;
  overflow: auto;
}




.navbar-expand .navbar-nav .nav-link {
  text-transform: uppercase;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  font-weight: 700;
}

nav {
  display: block;

  .navbar-nav {
    list-style: none;
  }

  .nav-link {
    box-shadow: 1px 1px 3px rgb(97 94 94 / 25%);
    padding: 10px 20px;
    display: inline-block;
    color: #333333;
    text-decoration: none;
    margin-bottom: 20px;
    margin-right: 10px;

    &:hover,
    &:active,
    &:focus {
      color: #333333;
    }

    &.router-link-active {
      background: #e6e6e6;
    }
  }
}

.buttons {
  clear: both;
  text-align: right;
}

.btn {
  margin: 10px;
  display: inline-block;
  padding: 12px 18px;
  font-size: 14px;
  font-weight: 700;
  line-height: 1.1;
  color: #333333;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: inherit;
  white-space: nowrap;
  vertical-align: middle;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-image: none;
  border: 0 !important;
  border-radius: 4px;

  &:hover {
    background: #c5c5c5;
  }
}

.button-icon {
  color: rgba(0, 0, 0, 0.54);

}

.material-hover {
  display: inline-flex;
  border-radius: 50%;
  transition: all 0.2s ease-in-out;
  width: 40px;
  min-width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.material-hover:hover {
  text-decoration: none;
  background-color: #e5e5e5;
  color: #141414;
}

.cenflow-logo {
  height: 100%;
  width: 100%;
  white-space: nowrap;

  text-align: center;
  margin: -60px 0 1em;

  .helper {
    display: inline-block;
    height: 100vh;
    vertical-align: middle;
  }

  img {
    vertical-align: middle;
  }
}
</style>
